import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import VirtualizedAutocompletePiezas from "../../../shared/components/AutocompletePiezas";
import { getAllowedExt } from "../../../shared/helpers/functionalities";

export default function DevolucionMercanciaForm({
    onSubmitDevolucion,
}) {
    const MAX_LENGTH = 3;
    const { 
        register, 
        handleSubmit, 
        setValue, 
        getValues,
        watch,
        control } = useForm({
            mode: "onChange",
            defaultValues: {
                damaged: 0,
                observaciones: '',
                files: null,
                pieza: null,
                pieza_id: null
            }
    });
 
    useEffect(() => {
        
    }, []); 

    

    const handleChangePieza = (pieza) => {
        setValue('pieza', pieza);
        setValue("pieza_id", pieza.id);
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();
        let dataAux;
        data.id_referencia_plataforma = data.pieza.id_referencia_plataforma
        delete data.pieza;
        delete data.damaged;

        if(data?.files?.length > MAX_LENGTH) {
            toast.warning(`No se pueden subir más de ${MAX_LENGTH} imágenes!!`);
            return;
        } else {
            if(data.files?.length > 0) {
                dataAux = new FormData();

                Object.entries(data).forEach((value) => {
                    if((value[0] !== 'files')) {
                        dataAux.append(value[0], value[1])
                    }
                });

                for (let i = 0; i < data.files?.length; i++) {
                    dataAux.append(`files[${i}]`, data.files[i])
                }
            } else { dataAux = data; }

            onSubmitDevolucion(dataAux);
        }
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100 d-flex flex-column">
                        <div className="form-group">
                            <label>Pieza</label>
                            <input type="hidden" 
                                {...register("pieza_id")} 
                                defaultValue={''} />

                            <VirtualizedAutocompletePiezas 
                                id={'pieza'}
                                control={control}
                                getValues={getValues}
                                labels={['id_referencia_plataforma', 'nombre']}
                                defaultValue={null}
                                onChange={handleChangePieza}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="observaciones">Observaciones</label>
                            <textarea
                                {...register("observaciones", {required: true})}
                                defaultValue={""}
                            ></textarea>
                        </div>

                        <div className="form-group">
                            <label>Tiene algún desperfecto respecto a las imágenes originales?</label>

                            <div className="d-flex align-items-center">
                                <input 
                                    type="checkbox"
                                    {...register("damaged")}
                                    name={`damaged`}
                                    defaultChecked={false}
                                />
                                    
                                <label className="m-0 ms-2">
                                    {watch('damaged') ? 'Si' : 'No'}
                                </label>
                            </div>
                        </div>

                        { (watch('damaged')) ?
                            <div className="form-group flex-grow-1">
                                <label htmlFor="files">Imágenes, <b>máximo 3</b></label>
                                <input 
                                    type="file" 
                                    className="form-control" 
                                    multiple
                                    accept={getAllowedExt('image')}
                                    {...register("files", {required: true})} 
                                    defaultValue={null} />
                            </div>
                            : null
                        }
                    </div>

                    <div className="d-flex align-content-center align-self-end">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}