import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Dialog, DialogContent, DialogTitle, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { faTimes, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrappingForm from "./components/ScrappingForm";
import PiezaForm from "./components/PiezaForm";
import ProveedorSearcher from "./components/ProveedorSearcher";
import ProveedorInfo from "./components/ProveedorInfo";
import PiezasSearcher from "./components/PiezasSearcher";
import PartSearcher from "./components/PartSearcher";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ProveedorPocoFiableModal from "./components/ProveedorPocoFiableModal";

export default function PiezaModal({
    open,
    isEdit,
    esVenta,
    onClose,
    onSubmitPieza,
    onPiezasSelector,
    onProveedorSelector,
    onOpenProveedorForm,
    onOpenServicioEnvio,
}) {
    const dispatch = useDispatch();
    
    const [tab, setTab] = useState('integracion');
    const presupuesto = useSelector(state => {
        return state.presupuesto.entity
    });
    const currentPieza = useSelector(state => {
        return state.currentPieza
    });
    const currentProveedor = useSelector(state => {
        return state.currentProveedor
    });
    const transportistas = useSelector(state => {
        return state.transportistas
    });
    const serviciosEnvio = useSelector(state => {
        const { servicios_envio } = state;
        return servicios_envio;
    });
    const piezasSchema = yup.object({
        url: yup.string().required(),
        id_referencia_plataforma: yup.string().required(),
        nombre: yup.string().required(),
        referencia_pieza: yup.string().required(),
        precio_original: yup.number().moreThan(0).required(),
        precio_compra: yup.number().moreThan(0).required(),
        precio_venta: yup.number().moreThan(0).required(),
        precio_transporte: yup.number().required(),
        tipo_pieza_id: yup.number().integer().required(),
        tipo_garantia_id: yup.number().integer().required(),
        marca_id: yup.number().integer().required(),
        modelo_id: yup.number().integer().moreThan(0).required(),
    }).required();
    const form = useForm({
        resolver: yupResolver(piezasSchema),
        mode: "onChange",
        defaultValues: {
            id: '',
            url: '',
            id_referencia_plataforma: '',
            nombre: '',
            referencia_pieza: '',
            precio_original: 0,
            precio_compra: 0,
            precio_venta: 0,
            precio_transporte: 0,
            casco: 0,
            tipo_pieza_id: -1,
            tipo_garantia_id: 1,
            marca_id: '',
            modelo_id: 0,
            modelo: null,
            km_motor: 0,
            unidades: 1,
            descuento: 0,
            con_descuento: 1,
            disponible: 1,
            tarifa_valor: 0,
            tarifa_tipo: '',
            integracion: 0,
            pieza_integracion_id: 0,
            integracion_json: '',
            imagenes: null
        }
    });
    const { getValues, reset, watch } = form;
    const [showUnreliableModal, setShowUnreliableModal] = useState(false);

    useEffect(() => {
        if(currentPieza) {
            reset(currentPieza);
        }

    }, [currentPieza, currentProveedor]);

    const handleCloseModal = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose();
    }
    
    const handleChangeTab = (e, tab) => {
        setTab(tab);
        dispatch({type: 'pieza/clear', payload: null});
        dispatch({type: "proveedor/clear", payload: null});
    }
   
    const handleSetProveedor = (proveedor) => { 
        dispatch({type: "proveedor/fullfilled", payload: proveedor});
    }

    const handleScrapping = (scrapping, scrappingURL) => {
        const data = setPiezaFromScrapping(scrapping, scrappingURL.url);

        // venimos de recambiofacil, con listado de piezas para seleccoinar
        if (scrapping.multiple){
            onPiezasSelector({scrapping: scrapping, url: scrappingURL.url});
            return;
        } 

        if (scrapping.proveedors){
            onProveedorSelector({scrapping: scrapping, url: scrappingURL.url});
            return;
        } else {
            dispatch({type: "pieza/fullfilled", payload: data});
            dispatch({type: "proveedor/fullfilled", payload: scrapping.proveedor});
        }        
    }

    const setPiezaFromScrapping = (scrapping, scrappingURL) => {
        return {
            ...scrapping,
            url: scrappingURL,
            precio_original: scrapping.precio_original ? parseFloat(scrapping.precio_original) : scrapping.precio_original,
            precio_compra: scrapping.precio_original ? parseFloat(scrapping.precio_original) : scrapping.precio_original,
            precio_transporte: scrapping.precio_envio ? parseFloat(scrapping.precio_envio) : 0,
            casco: 0,
            marca_id: scrapping.marca_id ? scrapping.marca_id : '',
            modelo_id: scrapping.modelo_id ? scrapping.modelo_id : '',
            tipo_pieza_id: scrapping.tipo_pieza_id ? scrapping.tipo_pieza_id : -1,
            km_motor: scrapping.kilometraje ? scrapping.kilometraje : '',
            observaciones: scrapping.observaciones ? scrapping.observaciones : '',
            con_descuento: true,
            unidades: 1
        }
    }

    const handleAddPieza = (pieza) => {
        dispatch({type: "pieza/fullfilled", payload: pieza});
        dispatch({type: "proveedor/fullfilled", payload: pieza.proveedor});
    }

    const handleSubmitPieza = async (data) => {
        data.proveedor_id = currentProveedor.id;
        data.servicio_envio = currentPieza.servicio_envio;
        dispatch({type: "pieza/fullfilled", payload: data});

        if(tab === "manual") {
            if(!checkIfIsReliableProveedor(currentProveedor)) {
                setShowUnreliableModal(true);
            } else {
                onSubmitPieza(data);
                setShowUnreliableModal(false);
            }
        } else {
            onSubmitPieza(data);
        }
    };

    const handleSubmitPiezaUnreiableProveedor = (confirm) => {
        if(confirm) {
            onSubmitPieza(currentPieza);
        }

        setShowUnreliableModal(false);
    }

    const handleSelectedPartFromScrapingByRef = (part) => {
        if (part.marketPlace === 'Integración') {
            handleAddPieza(part);
            return;
        }

        const data = setPiezaFromScrapingByRef(part);

        if (data.proveedors) {
            onProveedorSelector({scrapping: data, url: data.url});
            return;
        }

        dispatch({type: "pieza/fullfilled", payload: data});
        dispatch({type: "proveedor/fullfilled", payload: data.proveedor});
    }

    const getTransportista = (transportistaId) => { return transportistas.find(t => t.id === transportistaId) }
    const getServicio = (servicioId) => { return serviciosEnvio.find(s => s.id === servicioId) }

    const setPiezaFromScrapingByRef = (part) => {
        const price = part.precio_original ? parseFloat(part.precio_original) : part.precio_original;

        return {
            ...part,
            precio_original: price,
            precio_compra: price,
            precio_transporte: part.precio_envio ? parseFloat(part.precio_envio) : 0,
            casco: 0,
            marca_id: part.marca_id ? part.marca_id : '',
            modelo_id: part.modelo_id ? part.modelo_id : '',
            tipo_pieza_id: part.tipo_pieza_id ? part.tipo_pieza_id : -1,
            km_motor: part.km_motor ? part.km_motor : 0,
            observaciones: '',
            con_descuento: true,
            unidades: 1,
        };
    }

    const handleChangeEnvioProveedor = (precio) => {
        dispatch({type: "pieza/servicio_envio_precio", payload: precio});
    }

    const checkIfIsReliableProveedor = (proveedor) => { return proveedor.score === null || (proveedor.score !== null && proveedor.score >= 6.5); }

    // <Tab label="Scrapping" value="scrapping" />
    // <Tab label="Buscar por Referencia" value='ScrapByRef' />

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseModal}
                fullWidth={true}
                maxWidth={'lg'}
                aria-labelledby="pieza-form-title"
                aria-describedby="pieza-form-description">
                <DialogTitle id="pieza-form-title">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <p className="text-uppercase fw-bold">{isEdit ? 'Editar' : 'Nueva'} Pieza</p>

                        <span title="cerrar" className="action" onClick={() => handleCloseModal()}>
                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                        </span>
                    </div>
                </DialogTitle>
                <DialogContent>
                { (!isEdit) ?
                    <div className="w-100">
                        { (!currentPieza && !currentProveedor) &&
                            <TabContext value={tab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChangeTab} aria-label="Pieza">
                                    <Tab label="Manual" value="manual" />
                                    <Tab label="Integración" value="integracion" />
                                </TabList>
                                </Box>
                                <TabPanel value="manual">
                                    <div className="w-100">
                                        <ProveedorSearcher 
                                            onNew={onOpenProveedorForm}
                                            onProveedorSelected={handleSetProveedor}
                                            onCancel={handleCloseModal}
                                        />
                                    </div>
                                </TabPanel>

                                <TabPanel value="scrapping">
                                    <div className="w-100">
                                        <ScrappingForm 
                                            onScrapping={handleScrapping}
                                        ></ScrappingForm>                                        
                                    </div>
                                </TabPanel>

                                <TabPanel value="ScrapByRef">
                                    <div className="w-100">
                                        <PartSearcher
                                            onSelectedPart={handleSelectedPartFromScrapingByRef}
                                        />
                                    </div>
                                </TabPanel>

                                <TabPanel value="integracion">
                                    <div className="w-100">
                                        <PiezasSearcher 
                                            onResultSelected={handleAddPieza} 
                                            onCancel={handleCloseModal}
                                        />
                                    </div>
                                </TabPanel>
                            </TabContext>
                        }

                        <div className="w-100">
                            { (currentProveedor) && 
                                <div className="w-100">
                                    <div className="w-100 d-flex align-items-center mb-3">
                                        <ProveedorInfo 
                                            proveedor={currentProveedor} 
                                            onEdit={onOpenProveedorForm}
                                        />

                                        <div className="flex-grow-1 d-flex align-items-center justify-content-end me-3">
                                            { (currentPieza?.servicio_envio) ?
                                                <>
                                                    <FontAwesomeIcon icon={faTruck} color="#215732" className="me-3"></FontAwesomeIcon>

                                                    { currentPieza.servicio_envio.transportista_id !== 1 && <p className="text-uppercase me-2">{getTransportista(currentPieza.servicio_envio.transportista_id)?.nombre}</p>}
                                                    <p className="text-uppercase">{getServicio(currentPieza.servicio_envio.servicio_envio_id)?.nombre}</p>
                                                    <span className="mx-2">-</span>
                                                    <p className="text-uppercase">{currentPieza.servicio_envio?.precio_transporte}€</p>
                                                </>

                                                : <p className="fw-bold text-danger">No hay envio seleccionado</p>
                                            }
                                        </div>

                                        <button 
                                            type="button" 
                                            className="btn btn-success" 
                                            onClick={() => onOpenServicioEnvio(getValues())}
                                            disabled={watch('tipo_pieza_id') === -1}
                                        >
                                            Envío
                                        </button>
                                    </div>

                                    <PiezaForm  
                                        form={form}
                                        pieza={currentPieza}
                                        proveedor={currentProveedor}
                                        tarifaClienteId={presupuesto?.cliente?.tarifa_cliente_id}
                                        isEditing={false}
                                        fromScrapping={tab === 'scrapping' || tab === 'ScrapByRef' ? true : false}
                                        onSubmitPieza={handleSubmitPieza}
                                        onCancel={handleCloseModal}
                                        onChangePrecioEnvioProveedor={handleChangeEnvioProveedor}
                                    ></PiezaForm> 
                                </div>
                            }
                        </div>
                    </div>
                    :
                        <div className="w-100">
                            <div className="w-100 d-flex align-items-center justify-content-between mb-3">
                                <ProveedorInfo 
                                    proveedor={currentProveedor} 
                                    onEdit={onOpenProveedorForm}
                                />

                                <div className="flex-grow-1 d-flex align-items-center justify-content-end me-3">
                                    { (currentPieza?.servicio_envio) ?
                                        <>
                                            <FontAwesomeIcon icon={faTruck} color="#215732" className="me-3"></FontAwesomeIcon>

                                            { currentPieza.servicio_envio.transportista_id !== 1 && <p className="text-uppercase me-2">{getTransportista(currentPieza.servicio_envio.transportista_id)?.nombre}</p>}
                                            <p className="text-uppercase">{getServicio(currentPieza.servicio_envio.servicio_envio_id)?.nombre}</p>
                                            <span className="mx-2">-</span>
                                            <p className="text-uppercase">{currentPieza.servicio_envio?.precio_transporte}€</p>
                                        </>

                                        : <p className="fw-bold text-danger">No hay envio seleccionado</p>
                                    }
                                </div>

                                <button 
                                    type="button" 
                                    className="btn btn-success" 
                                    onClick={() => onOpenServicioEnvio()}
                                    disabled={watch('tipo_pieza_id') === -1}>
                                    Envío
                                </button>
                            </div>

                            <PiezaForm 
                                form={form}
                                pieza={currentPieza}
                                proveedor={currentProveedor}
                                tarifaClienteId={presupuesto?.cliente?.tarifa_cliente_id}
                                isEditing={true}
                                fromScrapping={null}
                                esVenta={esVenta}
                                onSubmitPieza={handleSubmitPieza}
                                onCancel={handleCloseModal}
                                onChangePrecioEnvioProveedor={handleChangeEnvioProveedor}
                            ></PiezaForm>
                        </div> 
                }
                </DialogContent>
            </Dialog>


            { (showUnreliableModal) &&
                <ProveedorPocoFiableModal
                    pieza={currentPieza}
                    onConfirmAction={handleSubmitPiezaUnreiableProveedor}
                    state={showUnreliableModal}>
                </ProveedorPocoFiableModal>
            }
        </div>
    )
}